/**
 * インポートファイル エンティティ
 */
import { isNud } from '@/utilities/typing';

class ImportFile {
    file_name;
    file_type;
    product_code;
    product_group_code;
    product_group_name;
    subgroup_sequence;
    error;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.file_name = properties.file_name;
        this.file_type = properties.file_type;
        this.product_code = properties.product_code;
        this.product_group_code = properties.product_group_code;
        this.product_group_name = properties.product_group_name;
        this.subgroup_sequence = properties.subgroup_sequence;
        this.error = properties.error;
    }
}

export default ImportFile;
