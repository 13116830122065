<template>
    <page-title
        icon="bi-upload"
        title="ファイル一括取込"
    >
    </page-title>

    <section class="section">
        <inline-loader v-if="loading"></inline-loader>
        <template v-else>
            <template v-if="import_files.length">
                <table-normal class="table">
                    <thead>
                        <tr class="table-dark">
                            <th class="text-center col-1">
                                <input type="checkbox"
                                    v-model="check_all"
                                    class="form-check-input"
                                    @change="checkAll()"
                                >
                            </th>
                            <th class="text-center">
                                ファイル名
                                <button-exec
                                    text=""
                                    icon="bi-arrow-clockwise"
                                    color="btn-secondary"
                                    class="ms-2"
                                    @click="refresh()"
                                ></button-exec>
                            </th>
                            <th class="text-center">ファイル種別</th>
                            <th class="text-center">コード</th>
                            <th class="text-center">名称</th>
                            <th class="text-center"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="import_file in import_files" :key="import_file.import_file_id">
                            <td class="text-center align-middle">
                                <input type="checkbox"
                                    :value="import_file.file_name"
                                    v-model="checked_files"
                                    class="form-check-input"
                                    v-if="!import_file.error"
                                >
                            </td>
                            <td class="align-middle text-center">{{ import_file.file_name }}</td>
                            <td class="align-middle text-center">{{ import_file.file_type }}</td>
                            <td class="align-middle text-center">{{ import_file.product_group_code }}</td>
                            <td class="align-middle text-center">{{ import_file.product_group_name }}</td>
                            <td class="text-danger small text-center">{{ import_file.error }}</td>
                        </tr>
                    </tbody>
                </table-normal>
                <p class="mt-3">
                    <button-exec
                        text="一括取込実行"
                        @click="bulkSaveFiles()"
                    ></button-exec>
                </p>
            </template>
            <alert-no-record v-else></alert-no-record>
        </template>
    </section>
    <section class="section">
        <div class="card">
            <div class="card-header">取り込み手順</div>
            <div class="card-body">
                <p>サーバ上の所定のフォルダ内（/laravel/storage/app/import/files）に、以下のファイル名でファイルを設置してください。</p>
                <table class="table">
                    <tbody>
                        <tr>
                            <th>製品グループ画像</th><td>g_image_{製品グループコード}_任意.拡張子</td>
                        </tr>
                        <tr>
                            <th>製品グループカタログ図</th><td>g_catalog_{製品グループコード}_任意.拡張子</td>
                        </tr>
                        <tr>
                            <th>製品グループ規格図</th><td>g_standard_{製品グループコード}_任意.拡張子</td>
                        </tr>
                        <tr>
                            <th>製品グループチラシ</th><td>g_flyer_{製品グループコード}_任意.拡張子</td>
                        </tr>
                        <tr>
                            <th>サブグループ画像</th><td>s_image_{製品グループコード}_{サブグループ連番}_任意.拡張子</td>
                        </tr>
                        <tr>
                            <th>サブグループカタログ図</th><td>s_catalog_{製品グループコード}_{サブグループ連番}_任意.拡張子</td>
                        </tr>
                        <tr>
                            <th>サブグループ規格図</th><td>s_standard_{製品グループコード}_{サブグループ連番}_任意.拡張子</td>
                        </tr>
                        <tr>
                            <th>サブグループ製作図</th><td>s_production_{製品グループコード}_{サブグループ連番}_任意.拡張子</td>
                        </tr>
                        <tr>
                            <th>製品画像</th><td>p_image_{製品コード}_任意.拡張子</td>
                        </tr>
                        <tr>
                            <th>製品製作図</th><td>p_production_{製品コード}_任意.拡張子</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</template>

<script>
import InlineLoader from '@/components/tools/InlineLoader.vue';
import ButtonExec from '@/components/buttons/ButtonExec.vue';
import TableNormal from '@/components/tables/TableNormal.vue';
import AlertNoRecord from '@/components/notice/AlertNoRecord.vue';
import PageTitle from '@/components/PageTitle.vue';
import ImportFile from '@/models/entities/import-file';

export default {
    name: 'ImportFileList',
    components: {
    InlineLoader,
    ButtonExec,
    TableNormal,
    AlertNoRecord,
    PageTitle,
},
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage',
    ],
    data() {
        return {
            //部分ローダー
            loading: 0,

            checked_files: [],
            check_all: false,

            //表示データ
            import_files: [],

        }
    },
    mounted() {
        //製品編集権限を持たない場合
        if (this.$store.getters['auth/canEditBasic']() === false) {
            this.showErrorMessage('アクセス権限がありません');
            this.$router.push({name: 'ProductGroupList'});
        }
        this.fetchImportFIles();
    },
    methods: {
        //インポート履歴マスタ取得
        fetchImportFIles() {
            this.loading++;

            this.import_files.splice(0);
            this.$http.get('/import/files')
            .then(response => {
                for (let row of response.data) {
                    this.import_files.push(new ImportFile(row));
                }
            })
            .finally(() => {
                this.loading--;
            });
        },
        bulkSaveFiles() {
            // チェックした商品 を一括操作の場合
            if (this.checked_files.length === 0) {
                this.showErrorMessage('画像が選択されていません。ファイル一覧よりチェックボックスで選択してください。');
                return;
            }

            this.startScreenLoading();
            this.saveFiles();
        },
        // ファイル保存
        saveFiles() {
            if (this.checked_files.length <= 0) {
                // 全ファイルの処理完了
                this.showMessage('画像を製品に設定しました');
                this.fetchImportFIles();
                this.endScreenLoading();
                // 再帰実行終了
                return;
            }

            // 現在の残りファイル数取得
            let file_count = this.checked_files.length;
            // 5ファイル取得
            let filenames = this.checked_files.slice(0, 5);
            // 5ファイルの処理実行
            this.$http.post(`/import/files`, {
                filenames
            })
            .then(() => {
                // 処理済みの5ファイル削除
                this.checked_files.splice(0, 5);
                // 再帰実行
                this.saveFiles()
            })
            .finally(() => {
                if (this.checked_files.length > file_count - 5) {
                    // 5ファイル減っていない場合はエラーとして中断
                    this.endScreenLoading();
                }
            });
        },
        refresh() {
            this.checked_files.splice(0);
            this.fetchImportFIles();
        },
        checkAll() {
            if (this.check_all) {
                this.checked_files.splice(0); //まず空に

                //各行エラーがなければ check ON に
                let files = [];
                this.import_files.forEach(function (import_file) {
                    if (!import_file.error) {
                        files.push(import_file.file_name);
                    }
                });
                this.checked_files = files;

            } else {
                //空に
                this.checked_files.splice(0);
            }
        },
    }
}
</script>

<style scoped>

</style>
